<template>
    <div class="login-page">
        <div id="particles">
        
        <el-container>
        <el-main>
            <div class="container">
                <div style="text-align: center">
                    <img v-if="webinfo['logo']" :src="base + webinfo['logo']" alt="logo" style="width:100px;border-radius:50%;"/>
                    <h3 style="color:#fff">{{ webinfo['admin_name'] }}</h3>
                </div>
                <div class="main">
                    <!-- label-width="auto" 导致ElementPlusError: [ElForm] unexpected width 0 -->
                    <el-form :model="form" size="large" :rules="loginRules" >
                        <el-form-item prop="account">
                            <el-input v-model="form.account" name="account" class="w-50 m-2" placeholder="请输入账号">
                                <template #prefix>
                                    <el-icon class="el-input__icon" style="color: #1890ff"><Avatar /></el-icon>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="form.password" name="password" type="password" class="w-50 m-2" placeholder="请输入密码" show-password>
                                <template #prefix>
                                    <el-icon class="el-input__icon" style="color: #1890ff"><Lock /></el-icon>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-row>
                                <el-col :span="14">
                                    <el-input v-model="form.code" name="code" placeholder="验证码">
                                        <template #prefix>
                                            <el-icon class="el-input__icon" style="color: #1890ff"><CircleCheck /></el-icon>
                                        </template>
                                    </el-input>
                                </el-col>
                                <el-col :span="9" style="float: right;line-height: 0;">
                                    <img style="width: 100%; height: 40px;border-radius: 5px;margin-left: 13px;" class="pointer" :src="src" @click="refreshCaptcha()" />
                                </el-col>
                            </el-row>

                        </el-form-item>
                        <!-- <el-form-item prop="remember">
                            <el-checkbox v-model="remember" label="1" size="large"><span style="color:#fff">自动登录</span></el-checkbox>
                        </el-form-item> -->
                        <el-form-item>
                            <el-button  style="width: 100%;color: #000;" @click="onSubmit()">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <el-footer>
                    <div style="text-align:center;color:#fff;">
                        Copyright © {{ webinfo['foot'] }}
                    </div>
                </el-footer>
            </div>
        </el-main>
    </el-container>
</div>
    </div>
    
</template>
<script>
import { reactive, toRefs } from "vue";
import { Login, Getconflists } from "@/network/login";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import particles from 'particles.js'
import particlesJson from '@/assets/particles/particles.json'

export default {
    name: "Login",
    mounted: function() {
        particlesJS('particles', particlesJson);
    },
    setup() {
        const base =(process.env.VUE_APP_PUBLIC_PATH); // 获取基本URL
        const src =[]
        const state = reactive({
            webinfo : [],
            remember: false,
            src: '',
            form: {
                account: "",
                password: "",
                code: "",
            },
        });

        const router = useRouter();

        Getconflists().then( (e)=>{
            // console.log(e)
            state.webinfo = e;
        } )
        const onSubmit = () => {
            Login(state.form).then((res) => {
                
                if (res != 1) {
                    window.localStorage.setItem("token", res.ticket);
                    window.localStorage.setItem("user", JSON.stringify(res));
                    ElMessage({ message: "登录成功", type: "success" });
                    router.push("/index/home");
                } else {
                    refreshCaptcha();
                    state.form.code = '';
                }
            });
        };
        const token = window.localStorage.getItem("token");

        const refreshCaptcha =()=> {
            state.src = base + "/admin/Login/verify?rand=" + new Date().getTime();
        };
        refreshCaptcha();
 
        if (token) {
            ElMessage({ message: "您已登录", type: "success" });
            router.push("/index/home");
        }
        return {
            ...toRefs(state),
            onSubmit,
            refreshCaptcha,
            base
        };
    }
};
</script>
<style>
.login-page {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: url(../assets/bj.png);
		background-size: 100% 100%;

        #particles {
			position: absolute;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			z-index: 2;
		}
}
.container {
    position: absolute;
    width: 100%;
    z-index: 3;
    /* min-height: 100%; */
    /* padding: 8% 0 0; */
    /* background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%; */
    margin-left: -20px;
    margin-top: 50px;
    margin-bottom: 100px;
}
.main {
    width: 368px;
    min-width: 260px;
    margin: 50px auto;
}

</style>