<template>
    <div>
        <div class="searchbar">
			<el-row class="pt20">
				<el-col :span="12">
					<el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
                    <el-row class="mb-4" style="margin-bottom: 10px;margin-top: -10px;">
                        <el-button type="success" plain  v-for="(item, index) in bigcate" :key="index" @click="bcate(item.id)">
                            <span v-if="ccate == item.id" style="color:red;">{{item.catname}}</span><span v-else>{{item.catname}}</span>
                        </el-button>
                    </el-row>
				</el-col>
                
				<el-col :span="12" style="text-align: right;">
                    
					<el-form :inline="true" :model="search" class="demo-form-inline">

						<el-form-item label="">
							<el-input v-model="search.value" size="mini" placeholder="请输入标题关键字"></el-input>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" size="mini" @click="onSearch">查询</el-button>
                            <el-button type="info" size="mini" @click="delSearch">取消查询</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</div>

		<el-table :data="tableData"  :key="index" style="width: 100%;margin-bottom: 20px;" row-key="n_id" :header-cell-style="{background:'#f9f9f9'}" stripe default-expand-all
		 v-loading="loading" >

			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column prop="n_id" label="ID" width="55"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="caten" label="所属分类" width="140"></el-table-column>
			<el-table-column prop="npic" label="图片" >
				<template #default="scope">
					<el-image  v-if="scope.row.npic" :preview-src-list="[base + scope.row.npic]" :src="base + scope.row.npic" fit="fill" preview-teleported style="margin-top: 5px;width: 30px;height: 30px;" ></el-image>
				</template> 
			</el-table-column>

            <el-table-column label="多图" >
              <template #default="scope">
                <template v-for="(it, i) in scope.row.test_img_many_s" :key="i">
                  <el-image :preview-src-list="[it.url]" :src="it.url" style="width: 30px;height: 30px;" preview-teleported />
                </template>
              </template>
            </el-table-column>
            
			

			<!-- <el-table-column prop="time" label="创建时间"></el-table-column> -->
			<el-table-column  label="操作">
				<template #default="scope">
                    <el-button type="primary" size="mini" icon="Edit" @click="showDialog(2,scope.row)">修改</el-button>
                    <el-button type="danger" size="mini" icon="Delete" @click="delData(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

        <el-pagination layout="total,prev, pager, next, jumper,  size" background :total="total" :page-size="limit" @current-change="CurrentChange" />
        <!-- 添加修改 -->
        <el-dialog v-model="dialog" :title="title" :fullscreen="sf">
            <suo-fang  v-on:customEvent="handleCustomEvent"></suo-fang>
            <el-form :model="form" label-width="120px" size="large">
                <el-form-item label="标题">
                    <el-input v-model="form.title" placeholder="请输入标题" />
                </el-form-item>
                
                

                <el-form-item label="归属" v-if="parent_show" >
                <el-tree-select
                    v-model="form.cate_id"
                    :data="cateData"
                    :normalizer="normalizer"
                    :render-after-expand="false"
                    :props="{ value: 'id',label: 'catname'}"
                    placeholder="选择归属类别"
                    style="width:100%"
                    :check-strictly="false" 
                />
                </el-form-item>

                <el-form-item label="自定义1">
                    <el-input v-model="form.keys" placeholder="请输入自定义1" />
                </el-form-item>
                <el-form-item label="自定义2">
                    <el-input v-model="form.content" placeholder="请输入自定义2" />
                </el-form-item>
                <el-form-item label="描述">
                    <el-input
                        v-model="form.desc"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        type="textarea"
                        placeholder="请输入描述"
                    />
                </el-form-item>
            

                <el-form-item label="图片:" :label-width="formLabelWidth" size="large" prop="npic">
                    <el-upload class="avatar-uploader" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="form.npic" :src="base + form.npic" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                </el-form-item>

                <!-- <el-form-item label="PDF:" :label-width="formLabelWidth" size="large" >
                    <el-input v-model="form.pdf" disabled placeholder="pdf文件路径" />
                    <el-upload class="upload-demo"  :limit="1" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccessPDF" :before-upload="beforeAvatarUpload">
                        <template #trigger>
                        <el-button type="primary">上传PDF</el-button>
                        </template>
                    </el-upload>
                </el-form-item> -->
                
                <el-form-item label="多图片:" :label-width="formLabelWidth" size="large" prop="mpic" >
                    <el-upload
                    v-model:file-list="imgList"
                    :action="upload_img"
                    list-type="picture-card"
                    :data="{ Authorization: token }"
                    :on-success="UploadImages"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    >
                    <el-icon><Plus /></el-icon>
                    </el-upload>
                    <el-dialog v-model="dialogVisible">
                        <img w-full :src="dialogImageUrl" alt="Preview Image" />
                    </el-dialog>
                </el-form-item>
                <el-form-item label="产品规格" v-if="type == 1">
                    <el-input
                        v-model="form.cpinfo"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        type="textarea"
                        placeholder="请输入产品规格"
                    />
                </el-form-item>

                


            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="default" @click="dialog = false">取消</el-button>
                    <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
   
</template>
<script>
import { ElMessage, ElMessageBox} from "element-plus";
import { toRefs,reactive, onMounted  } from "vue";
import { NewsLists, NewsSave, NewsDel, BigCate } from "@/network/ads";


import SuoFang from '@/components/SuoFang.vue';
export default {
    name: "NewsList",
    components: {
        SuoFang
    },
    setup() {
        onMounted(()=>{
            checkDeviceType();
        });
        const base =(process.env.VUE_APP_PUBLIC_PATH); // 获取基本URL
        const state = reactive({
            sf:false,
            tableData:[],
            cateData:[],
            imgList: [],
            dialog : false,
            parent_show : true,
            src_show : true,
            title : "添加",
            total: 1,
            tpage: 1,
            limit: 10,  //设置分页数
            //多图上传  图片弹框放大
            dialogImageUrl: "",
            dialogVisible: false,
            bigcate:"",
            ccate:"",
            form: {
                cate_id:0,
                caten: "",
                n_id : 0,
                content : "",
                title: "",
                time:"",
                npic:"",
                mpic:""

            },
            search : {
                value : '',
            },
            upload_img: base + "/admin/Index/upload_img",
            token: window.localStorage.getItem("token"),
        });
        NewsLists().then((res)=>{
            state.tableData = res.lists;
            state.total = res.total;
            // console.log(res)
        } );
        BigCate().then((res)=>{
            console.log(res);
            state.bigcate = res;
            state.cateData = res;
        });
        
        // 翻页方法
        const CurrentChange = (e)=>{
            console.log('e',e)
            
            if(state.ccate){
                NewsLists({p:e,value:state.ccate,type:2}).then((res) => {
                        state.tableData = res.lists;
                        state.tpage = e;
                });

            } else{
                NewsLists({p:e,value:state.search.value}).then((res) => {
                        state.tableData = res.lists;
                        state.tpage = e;
                });

            }
            
        }
        // 搜索
        const onSearch = () => {
            // console.log('ss',state.search.value)
            NewsLists({value:state.search.value,p:state.tpage}).then((res) => {
                state.ccate = '';
                state.tableData = res.lists;
                state.total = res.total;
            });
        }
        const delSearch = () => {
            state.search.value = ''
            NewsLists({p:state.tpage}).then((res) => {
                state.tableData = res.lists;
                state.total = res.total;
            });
        }
        //ADD AND SAVE
        const showDialog = (e,row)=>{
            // console.log(row)
            state.dialog = true;
            if(e == 1){
                state.form = {
                  n_id:0,
                  title: "",
                  cate_id : "",
                  content : "",
                  npic:"",
                  mpic:"",
                  keys:"",
                  desc:"",
                  pdf:"",
                  cpinfo:""
                  
                };
                state.imgList = [];
                state.title = "添加";
                state.type = 0;
            }else{
                state.title = "修改";
                state.form = {
                    n_id: row.n_id,
                    cate_id: row.cate_id,
                    title: row.title,
                    content: row.content,
                    npic:row.npic,
                    mpic:row.mpic,
                    keys:row.keys,
                    desc:row.desc,
                    pdf:row.pdf,
                    cpinfo:row.cpinfo
                };
                if (state.form.mpic) {
                    state.imgList = row.test_img_many_s.map((item) => {
                        return { uid: item.uid, url: item.url, data: item.data };
                    });
                    state.form.mpic = row.test_img_many_s.map((item) => {
                        return item.data;
                    });
                    } else {
                        state.imgList = [];
                   }
            }
        }
        const submitForm = ()=>{
            
            if(state.form.mpic){
                state.form.mpic = state.form.mpic.join(";");
            }
            NewsSave(state.form).then( (e)=>{
                if (e != 1) {
                    
                    ElMessage({ message:'成功', type: "success" });
                    
                    if(state.ccate){
                        bcate(state.ccate);
                        state.dialog = false;
                    } else if(state.search.value){
                        onSearch(state.search.value);
                        state.dialog = false;
                    } else{
                        NewsLists({p:state.tpage}).then( (e)=>{
                            state.tableData = e.lists;
                            state.total = e.total;
                            state.dialog = false;
                            
                        } )
                    }
                    
                   
                    
                    
                }
            } )
        }
        //上传
         // 管理员token，上传图片接口需要
         const token = window.localStorage.getItem("token");
        //单图片上传
        const beforeAvatarUpload = (file) => {
            const isJPG = file.type === "image/png" || "image/jpg" || "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isJPG) {
                ElMessage({ message: "上传头像图片只能是 JPG/PNG/JPEG 格式!", type: "error" });
            }
            if (!isLt2M) {
                ElMessage({ message: "上传头像图片大小不能超过 3MB!", type: "error" });
            }
            return isJPG && isLt2M;
        };
        const handleAvatarSuccess = (response, uploadfile) => {
            
            state.form.npic = response.data;
            state.form.img = URL.createObjectURL(uploadfile.raw);
            // console.log(state.form.img)
        };
        //单文件pdf上传成功
        const handleAvatarSuccessPDF = (response, uploadfile) => {
            state.form.pdf = response.data;
        };
        //多图文件上传
        const UploadImages = (response, uploadfile) => {
                state.form.mpic = state.imgList.map((item) => {
                    if (item.data) {
                        return item.data;
                    } else {
                        return item.response.data;
                    }
                });

                // console.log(state.form.mpic,'&&',response);
       };
                const handlePictureCardPreview = (uploadFile, uploadFiles) => {
                    state.dialogImageUrl = uploadFile.url;
                    state.dialogVisible = true;
                };
                const handleRemove = (uploadFile, uploadFiles) => {
                    state.form.mpic = uploadFiles.map((item) => {
                        if (item.raw != null) return item.response.data;
                        else return item.data;
                    });
                    console.log(state.form.mpic);
        };
        const delData = (row={})=>{
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "删除："+row.title, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then( ()=>{
                NewsDel({n_id:row.n_id}).then( (e)=>{
                    if (e != 1) {
                        ElMessage({ message:'成功', type: "success" });
                        if(state.ccate){
                            bcate(state.ccate);
                        } else if(state.search.value){
                            onSearch(state.search.value);
                        } else{
                            NewsLists({p:state.tpage}).then( (e)=>{
                                state.tableData = e.lists;
                                state.total = e.total;    
                            } )
                        }
                    }
                } )
            } ).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        };
        
        //接收子组件数据
        const handleCustomEvent = (data) => {
            state.sf = data; // 处理接收到的数据
        };
        //查是否手机登陆
        const checkDeviceType =() => {
            const userAgentInfo = navigator.userAgent;
            if (/(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)/.test(userAgentInfo)) {
                
                state.sf = true; 

            } else {
                state.sf = false; // 若是移动设备则隐藏该div
            }
        };

        //选择大类列表
        const bcate =(id)=>{
            state.ccate = id;
            state.search.value = '';
            NewsLists({type:2,value:state.ccate,p:state.tpage}).then((res) => {
                state.tableData = res.lists;
                state.total = res.total;
            });
        };
        return {
            base,
            ...toRefs(state),
            CurrentChange,
            onSearch,
            delSearch,
            showDialog,
            submitForm,
            beforeAvatarUpload,
            handleAvatarSuccess,
            handleAvatarSuccessPDF,
            delData,
            //多图
            UploadImages,
            handlePictureCardPreview,
            handleRemove,

            handleCustomEvent,
            bcate

        };
    }
}   
</script>
<style>
.avatar-uploader .avatar {
  width: 78px;
  height: 78px;
  display: block;
}

.el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
 
.el-upload-list--picture-card .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}
</style>